// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,900);

// Bootstrap
@import '~bootstrap';

// Font-awesome
$fa-font-path: '../fonts';
@import 'node_modules/font-awesome/scss/font-awesome';

// Dashboard theme
@import 'node_modules/gentelella/build/css/custom.min';

// Datepicker
@import 'node_modules/@fengyuanchen/datepicker/dist/datepicker.min';

// Multiselect
@import 'node_modules/select2/dist/css/select2.min';

// Parsleyjs : form validation
@import 'node_modules/parsleyjs/src/parsley';

@import 'input-table';
@import 'tables';
@import 'praticiens/form';
@import 'filter-sidebar';
@import 'tags';
@import 'errors';
@import 'login';
@import 'variations-indicator';

// Overrides the color of the outline of the profile picture in the sidebar
.img-circle.profile_img {
  background: #4a6884;
}
