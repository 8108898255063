.filter-sidebar
{
  min-height: 100vh;
  margin-left: -20px;
  margin-top: -10px;

  padding: 20px;

  background: #ffffff;
  border: 1px solid #e6e9ed;

  .toggle-filter-sidebar {
    position: absolute;
    right: 14px;
    top: 8px;
    font-size: 20px;
    cursor: pointer;
  }
}
