
.custom-tag
{
  background-color: #1abb9c;
  border: 1px solid #12a987;
  margin-right: 3px;
  padding: 3px 5px;
  color: white;

  &:hover
  {
    color:white;
    background-color: #259c85;
  }
}
