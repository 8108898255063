.variation-indicator-neutral {
  font-size: 0.9em;
  color: #a9b5ce;
}

.variation-indicator-positive {
  font-weight: bold;
  font-size: 0.9em;
  color: #6db369;
}

.variation-indicator-negative {
  font-weight: bold;
  font-size: 0.9em;
  color: #c73f61;
}