.login-page {
  background: #2A3F54;
  color: #363636;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;

  .login-card {
    background: #f8f8f8;
    border-radius: 3px;
    width: 350px;
    display: flex;
    flex-direction: column;
    padding: 50px 30px;
    box-shadow: 0 0 20px rgba(0,0,0,0.2);

    .title {
      align-self: center;
      margin-bottom: 2.5rem;
      font-weight: 400;
    }
  }
}