.remove-link
{
    color: #ff3b30;
}

.add-link
{
    color: #4CD964;
}

.family-card-container
{
    text-align: center;

    .family-card
    {
        display: inline-block;
        vertical-align: top;

        margin: 15px;
        text-align: center;

        .img-container
        {
            position: relative;

            img
            {
                border-radius: 100%;
                width: 100px;
                height: 100px;
                margin: 15px auto;

                border: 4px solid white;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
            }

            .family-card-remove-icon
            {
                position: absolute;
                top: 23px;
                left: 0;
                right: 0;

                display: none;

                color: #2a3f54;
                font-size: 4em;
            }
        }

        strong
        {
            display: block;
        }

        a i.fa.fa-minus-circle
        {
            display: none;
        }

        &:hover .img-container .family-card-remove-icon
        {
            display: block;
        }
    }

    .family-card-form
    {
        display: inline-block;
        vertical-align: top;

        border-radius: 5px;
        border: 1px solid #ddd;

        padding: 15px;
        //background: #73879C;

        input[type=text], select
        {
            display: block;
            width: 100%;
            height: 2em;
            padding-left: 3px;

            margin-bottom: 10px;
        }

        label
        {
            //color: white;
        }
    }
}

.pre-description-preview
{
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 1.3em;
}