
/*
 * Action icons on rows (visible on hover only)
 */

.table-row-actions a
{
    visibility: hidden;

    .fa {
        font-size: 16px;
    }
}

tr:hover .table-row-actions a
{
    visibility: visible;
}
