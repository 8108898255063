
.input-table
{
    width: 100%;
    border-collapse: collapse;

    td
    {
        // Imitates bootstrap <td>
        padding: 8px;
        line-height: 1.42857;
        vertical-align: top;

        &.label-column
        {
            width: 50px;
            padding: 8px 0;

            input.form-control
            {
                color: #007AFF;
                padding-left: 0;
                padding-right: 0;
            }
        }

        &.icon-column
        {
            vertical-align: middle;
            width: 30px;
            padding: 8px 0;

            .fa
            {
                margin: 5px;
            }
        }

        .input-column
        {
            padding-right: 0;
        }
    }

    tr:not(:last-child)
    {
        border-bottom: 1px solid #ddd;
    }

    input
    {
        border: none;
        box-shadow: none;
        border-bottom: 2px solid #ececec;
        width: 100%;
    }
}
