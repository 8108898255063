
.error-container
{
  .error-banner
  {
    background: white;
    padding: 3em 2em;
    position: absolute;
    top: 7em;
    width: 100%;

    h1
    {
      font-family: Roboto;
      font-weight: 300;
      font-size: 4em;
    }

    p
    {
      font-family: "Open Sans";
      font-weight: 300;
      font-size: 1.5em;
    }
  }
}

.banner-red
{
  padding: 10px;
  background: #842222;
  color: white;
}
